import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));


// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography width={180} height={120} sx={{  mt:'15px', display: 'block', marginLeft: '140px'}}>
          <img src="https://storage.googleapis.com/asiamobiliti-static-assets/trek_logomark.png" alt="Trek Logo" />
        </Typography>
        <Typography variant="h3" sx={{ px: 5, mt: 5, mb: 5, textAlign: 'center' }}>
          Welcome Back !
        </Typography>
        <img src="/static/illustrations/2007.i039.019_cyber_security_spyware_data_protection_isometric_set-06.jpg" alt="login" />
        <Typography sx={{ px: 5, mt: 5, mb: 5, textAlign: 'left', fontSize: '14px' }}>
          Copyright © {new Date().getFullYear().toString()} Asia Mobility Technologies Sdn Bhd. All Rights Reserved.
        </Typography>
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Trek Rides Admin Console
            </Typography>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
