/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';

import { AllMandatoryFilled, requiredField } from '../../utils/config';
import { APIGetDriver, APICreateVehicle, APIUpdateVehicle } from '../../utils/apiCalled';
// import { getImageFirebase } from '../../utils/getImageFirebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    bgcolor: 'background.paper',
    width: '60vw',
    boxShadow: 24,
    p: 4,
};
  
const loadingStyle = {
    position: 'absolute',
    top: '45%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
};

export default function ModalVehicle({
    handleClose, modalClick, label, vehicleList, editState,
    setOpen, setSuccMsg, setErrMsg, setVariant, setTempNextPage, setPage,
    setModalTriggerAPICalled,
  }) {
  
    const [vehicleValue, setVehicleValue] = useState({
        vehicleNumber: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.vehicleNumber : '',
        brand: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.brand : '',
        model: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.model : '',
        colour: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.colour : '',
        permitNumber: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.permitNumber : '',
        permitValidity: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.permitValidity : '',
        status: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.status : '',
        capacity: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.capacity : '',
        currentDriverId: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.currentDriverId : '',
        currentDriverName: vehicleList && Object.keys(vehicleList).length > 0 && vehicleList.driverFirstName && vehicleList.driverLastName ? `${vehicleList.driverFirstName} ${vehicleList.driverLastName}` : '',
        vehicleType: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.vehicleType : '',
    })
    const [getDriver, setGetDriver] = useState([]);
    const [disButton, setdisButton] = useState(true);
    const [loadings, setLoading] = useState(false);
    const [posLoading, setPosLoading] = useState(false);
  
    // to get driver list
    const getAPICalled = async () => {
        try {
          const result = await APIGetDriver(setLoading);
        //   console.log(result);
          if (result.status === 200) {
            const { data } = result;
            const reducer = data.length > 0 ? data.map((x) => { return {
                _id: x._id,
                firstName: x.firstName,
                lastName: x.lastName,
                fullName: `${x.firstName} ${x.lastName}`,
            }}): []
            setGetDriver(reducer);
            setLoading(false);
          } else {
            setLoading(false);
            setOpen(true);
            setVariant('error');
            setErrMsg('Error!, Please try again');
          }
        } catch (err) {
          // console.log('GO HERE IF ERROR', err);
          setLoading(false);
          setOpen(true);
          setVariant('error');
          setErrMsg('Error!', err);
        }
    };
  
    // this to remove all the state
    const removeAllState = () => {
      setVehicleValue({
        vehicleNumber: '',
        brand: '',
        model: '',
        colour: '',
        permitNumber: '',
        permitValidity: '',
        status: '',
        capacity: '',
        currentDriverId: '',
        vehicleType: '',
      })
    //   setNewUpload(false);
    //   setNewUploadProfile(false);
    //   setDriverImg(null);
    //   setNricImg(null);
    //   setClickOnce({
    //     dImg: false,
    //     icImg: false,
    //   })
      handleClose();
    };
  
    // to called the add / edit api
    const apiAddEditCalled = async (value, id) => {
      try {
        const result = editState ? await APIUpdateVehicle(setPosLoading, id, value) : await APICreateVehicle(setPosLoading, value)
        // console.log('result from createvehicle api', result);
        if (result.status === 200) {
          setPosLoading(false);
          removeAllState();
          setOpen(true);
          setVariant('success');
          setSuccMsg('Thank you! Your submission has been sent')
          setModalTriggerAPICalled(true);
        } else {
          setPosLoading(false);
          removeAllState();
          setOpen(true);
          setVariant('error');
          setErrMsg('Error!, Please try again');
        }
      } catch (err) {
        setPosLoading(false);
        removeAllState();
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${err}`);
      }
    };
  
    // first parameter from state and second parameter from API
    // "argValue" are from vehicleValue, "vehicleList" are from the api result
    const arrangeInput = (stateValue, apiValue) => {
      const input = {};
      if (stateValue.vehicleNumber !== apiValue.vehicleNumber) {
        input.vehicleNumber = stateValue.vehicleNumber;
      };
      if (stateValue.brand !== apiValue.brand) {
        input.brand = stateValue.brand;
      };
      if (stateValue.model !== apiValue.model) {
        input.model = stateValue.model;
      };
      if (stateValue.colour !== apiValue.colour) {
        input.colour = stateValue.colour;
      };
      if (stateValue.capacity !== apiValue.capacity) {
        input.capacity = stateValue.capacity;
      };
      if (stateValue.status !== apiValue.status) {
        input.status = stateValue.status;
      };
      if (stateValue.permitNumber !== apiValue.permitNumber) {
        input.permitNumber = stateValue.permitNumber;
      };
      if (stateValue.permitValidity !== apiValue.permitValidity) {
        input.permitValidity = stateValue.permitValidity;
      };
      if (stateValue.vehicleType !== apiValue.vehicleType) {
        input.vehicleType = stateValue.vehicleType;
      };
      if (stateValue.currentDriverId) {
        input.currentDriverId = stateValue.currentDriverId;
      };
      return input;
    };

      // checking for mandatory field in edit module. Btn will disable if there is no changes.
    const checkDetailsChanges = () => {
      const inputChecking = arrangeInput(vehicleValue, vehicleList);
      const checking = Object.keys(inputChecking).length > 0;
      return checking;
    };

  
    // handlesubmit button
    const handleSubmit = (value) => {
      if (editState) {
        const input = arrangeInput(vehicleValue, vehicleList)
        const { _id } = vehicleList;
        // console.log(input, _id);
        apiAddEditCalled(input, _id)
      } else {
        apiAddEditCalled(value, false);
      }
    };

    //checing validation for button disabled
    const checkValidationVehicle = () => {
      const checkValidation = AllMandatoryFilled(requiredField.vehicle, vehicleValue);
      return checkValidation;
    };
  
    // first time load will called the getDriver API
    useEffect(() => {
        getAPICalled();
    }, []);

    // this for disabled submit button
    useEffect(() => {
      if (editState) { // check if it in edit modal
        if (checkValidationVehicle() && checkDetailsChanges()) {
          setdisButton(true);
        } else {
          setdisButton(false);
        }
      } else if (checkValidationVehicle()) { // check if in add modal
        setdisButton(true);
      } else {
        setdisButton(false);
      }
    }, [vehicleValue])
  
    // to display the value in edit page
    useEffect(() => {
      if (editState) {
        setVehicleValue({
          vehicleNumber: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.vehicleNumber : '',
          brand: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.brand : '',
          model: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.model : '',
          colour: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.colour : '',
          permitNumber: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.permitNumber : '',
          permitValidity: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.permitValidity : '',
          status: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.status : '',
          capacity: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.capacity : '',
          currentDriverId: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.currentDriverId : '',
          currentDriverName: vehicleList && Object.keys(vehicleList).length > 0 && vehicleList.driverFirstName && vehicleList.driverLastName ? `${vehicleList.driverFirstName} ${vehicleList.driverLastName}` : '',
          vehicleType: vehicleList && Object.keys(vehicleList).length > 0 ? vehicleList.vehicleType : '',
        })
      } else {
        setVehicleValue({
          vehicleNumber: '',
          brand: '',
          model: '',
          colour: '',
          permitNumber: '',
          permitValidity: '',
          status: '',
          capacity: '',
          currentDriverId: '',
          currentDriverName: '',
          vehicleType: '',
        })
      }
    }, [vehicleList]);

    // this onChange for a textfield
    const vehicleOnChange = (e, names) => {
      const valueName = names;
      const target = e.target.value;
      if (names === 'capacity') {
        const convertNum = target ? parseInt(target) : null;
        setVehicleValue({ ...vehicleValue, [valueName]: convertNum });
      } else {
        setVehicleValue({ ...vehicleValue, [valueName]: target });
      }
    };
  
    // this for selection component onChange status and vehicle type
    const statusOnChange = (e, names) => {
      const targetValue = e.target.value; // get the value
      const fieldName = names; // fieldName
      setVehicleValue({ ...vehicleValue, [fieldName]: targetValue });
    };
  
    // this for datePicker component permit validity
    const onDateChange = (newValue) => {
      const dateTime = moment(newValue).format('YYYY-MM-DDT00:00:00');
      setVehicleValue({ ...vehicleValue, permitValidity: dateTime });
    }

    // this for autocomplete component onChange (driver)
    const handleChangeAutocomplete = (e, value, name, reason) => {
      if (reason === 'clear') {
        setVehicleValue({ ...vehicleValue, [name]: '', currentDriverName: '' });
      } else {
        setVehicleValue({ ...vehicleValue, [name]: value._id, currentDriverName: value.fullName });
      }
    };
  
    return (
      <Modal
        open={modalClick}
        onClose={removeAllState}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {(loadings || posLoading) && <CircularProgress color="success" sx={loadingStyle} />}
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            { label }
            <br />
            <br />
            <div style={{
              display: 'grid',
              gridAutoColumns: '1fr',
              gridTemplateColumns: '1fr 1fr',
              gap: '0px 24px',
              gridTemplateAreas: '. .',
              justifyContent: 'center',
              width: '100%',
            }}
            >
              <div>
                <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row', gap: '8px' }}>
                      <TextField sx={{ width: '100%'}} required id="standard-basic" label="Vehicle Number" value={vehicleValue.vehicleNumber} variant="outlined" onChange={(e) => vehicleOnChange(e, 'vehicleNumber')} />
                      <TextField sx={{ width: '100%'}}  required id="standard-basic" label="Colour" value={vehicleValue.colour} variant="outlined" onChange={(e) => vehicleOnChange(e, 'colour')} />
                </FormControl>
                <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row', gap: '8px' }}>
                  <TextField sx={{ width: '100%'}} required id="standard-basic" label="Brand" value={vehicleValue.brand} variant="outlined" onChange={(e) => vehicleOnChange(e, 'brand')} />
                  <TextField sx={{ width: '100%'}} required id="standard-basic" label="Model" value={vehicleValue.model} variant="outlined" onChange={(e) => vehicleOnChange(e, 'model')} />
                </FormControl>
                <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                    <Autocomplete
                      loading={loadings}
                    value={vehicleValue.currentDriverName}
                    getOptionLabel={(option) => option.fullName ?? option}
                    onChange={(e, newValue, reason) => {
                        handleChangeAutocomplete(e, newValue, 'currentDriverId', reason);
                    }}
                    options={getDriver}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        labelId="demo-simple-select-standard-label"
                        label="Current Driver"
                        />
                    )}
                />
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ 
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '0px 8px',
                    gridTemplateAreas: '. .',
                    justifyContent: 'center',
                    marginBottom: '16px',
                }}>
                  <TextField required id="standard-basic" sx={{ width: '100%' }} value={vehicleValue.permitNumber} label="Permit Number" variant="outlined" onChange={(e) => vehicleOnChange(e, 'permitNumber')} />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disablePast
                            sx={{ width: '100%' }}
                            label="Permit Validity"
                            inputFormat="dd/MM/yyyy"
                            value={vehicleValue.permitValidity}
                            onChange={(newValue) => onDateChange(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                    <TextField required id="standard-basic" type="number" sx={{ width: '100%', marginRight: '8px' }} value={vehicleValue.capacity} label="Seating" variant="outlined" onChange={(e) => vehicleOnChange(e, 'capacity')} />
                </FormControl>
                <div style={{ marginBottom: '16px', display: 'flex', flexDirection:'row', gap: '8px' }}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel variant="outlined" required htmlFor="uncontrolled-native">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={vehicleValue.status}
                            onChange={(e) => statusOnChange(e, 'status')}
                            label="Status"
                        >
                            <MenuItem value='ACTIVE'>Active</MenuItem>
                            <MenuItem value='INACTIVE'>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel variant="outlined" required htmlFor="uncontrolled-native">Vehicle Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={vehicleValue.vehicleType}
                            onChange={(e) => statusOnChange(e, 'vehicleType')}
                            label="Vehicle Type"
                        >
                            <MenuItem value='VAN'>Van</MenuItem>
                            <MenuItem value='MPV'>MPV</MenuItem>
                            <MenuItem value='BUS'>Bus</MenuItem>
                        </Select>
                    </FormControl>
                </div>
              </div>
            </div>
          <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
              <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeAllState()}>Cancel</Button>
              <Button variant="contained" sx={{ width: 90 }} disabled={!disButton} onClick={() => handleSubmit(vehicleValue)}>Save</Button>
          </Stack>
          </Typography>
        </Box>
      </Modal>
    );
  }