import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  width: '60vw',
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  transform: 'translate(-100%, -100%)',
};

export default function ModalConfirmRefund({
  handleConfirmClose, modalConfirm, handleConfirm, reason, amount, tripId, loading, disableConfirmButton, disableCancelButton
}) {
  const userId= Cookies.get('userId');
  const twoDigit = (+amount).toFixed(2).toString();
  const refundValue = {
    tripId,
    amount: twoDigit,
    refundReason: reason,
    adminUserId: userId,
  }
  return (
    <Modal
      open={modalConfirm}
      onClose={handleConfirmClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loading && <CircularProgress color='success' sx={loadingStyle} /> }
        <Typography id="modal-modal-title" sx={{ fontSize: 18, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          Please check details before proceeding
          <Typography id="modal-modal-title" sx={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'left', overflowWrap: 'break-word'}}>
            <br />
            Trip Id : {tripId}
            <br />
            <br />
            Amount: MYR {twoDigit}
            <br />
            <br />
            Reason: {reason}
          </Typography>
        </Typography>
        <Stack spacing={20} direction="row" alignItems="center" sx={{ marginTop: '20px', }}>
          <Button variant="outlined" sx={{ width: '20%' }} disabled={disableCancelButton} onClick={() => handleConfirmClose()}>Go Back</Button>
          <Button variant="contained" sx={{ width: '20%' }} disabled={disableConfirmButton} onClick={() => handleConfirm(refundValue)}>Confirm</Button>   
        </Stack>
      </Box>
    </Modal>
  );
}