/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AllMandatoryFilled, requiredField } from '../../utils/config';
import { APICreateUser, APIUpdateUser } from '../../utils/apiCalled';
import { ValidationError } from 'yup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  width: '45vw',
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  transform: 'translate(-50%, -50%)',
};

export default function ModalUser({
  handleClose, modalClick, label, userList, editState,
  setOpen, setSuccMsg, setErrMsg, setVariant,
  setModalTriggerAPICalled,
}) {

//   console.log('userList', userList);
  const convertPhoneNo = (value) => {
    let textValue;
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const isSpecialCharsPresent = specialChars.split('').some(char => 
      value.includes(char)) // true if present and false if not
    
    if (isSpecialCharsPresent) {
      textValue = value;
    } else {
      textValue = `+${value}`;
    }
    return textValue;
  }

  const [userValue, setUserValue] = useState({
    firstName: userList && Object.keys(userList).length > 0 ? userList.firstName : '',
    lastName: userList && Object.keys(userList).length > 0 ? userList.lastName : '',
    email: userList && Object.keys(userList).length > 0 ? userList.email : '',
    phone: userList && Object.keys(userList).length > 0 ? convertPhoneNo(userList.phone) : '',
    firebaseUUID: userList && Object.keys(userList).length > 0 ? userList.firebaseUUID : '',
  });
  const [disButton, setdisButton] = useState(true);
  const [loadings, setLoading] = useState(false);

  const removeAllState = () => {
    setUserValue({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      firebaseUUID : '',
    });
    handleClose();
  };

  // to called the add / edit api
  const apiAddEditCalled = async (value, id) => {
    // console.log('value', value);
    try {
      const result = editState
        ? await APIUpdateUser(setLoading, id, value)
        : await APICreateUser(setLoading, value);
      // console.log('result', result);
      if (result.status === 200) {
        setLoading(false);
        removeAllState();
        setOpen(true);
        setVariant('success');
        setSuccMsg('Thank you! Your submission has been sent');
        setModalTriggerAPICalled(true); // this to trigger apiCalled in POI useEffect
      } else {
        setLoading(false);
        removeAllState();
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      removeAllState();
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
    }
  };

  // first parameter object are the state and second parameter
  // "argValue" are from driverValue, "driverList" are from the api result
  const arrangeInput = (stateValue, apiValue) => {
    const input = {};
    if (stateValue.firstName !== apiValue.firstName) {
        input.firstName = stateValue.firstName;
    }
    if (stateValue.lastName !== apiValue.lastName) {
        input.lastName = stateValue.lastName;
    }
    if (stateValue.firebaseUUID !== apiValue.firebaseUUID) {
        input.firebaseUUID = stateValue.firebaseUUID
    }
    return input;
  };

  // this redicer for removing the "+" symbol
  const reducerAdd = (value) => {
    const removeSpecialChar = value.phone.replace("+",'');
    const add = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phone: removeSpecialChar,
        firebaseUUID: value.firebaseUUID,
    }

    return add;
  }

  // handlesubmit button
  const handleSubmit = (value) => {
    if (editState) {
      const input = arrangeInput(value, userList)
      const { _id } = userList;
      // console.log(input);
      apiAddEditCalled(input, _id)
    } else {
        const reducer = reducerAdd(value);
        apiAddEditCalled(reducer, false);
    }
  };

  // checking for mandatory field in edit module. Btn will disable if there is no changes.
  const checkDetailsChanges = () => {
    const inputChecking = arrangeInput(userValue, userList);
    const checking = Object.keys(inputChecking).length > 0;
    return checking;
  };

  // checking value for mandatory field
  const checkMandatoryField = () => {
    const checkValidation = AllMandatoryFilled(requiredField.user, userValue);
    return checkValidation;
  };

  // this to do the button submit validation (disable button)
  useEffect(() => {
    if (editState) { // check if it in edit modal
      if (checkMandatoryField() && checkDetailsChanges()) {
        setdisButton(true);
      } else {
        setdisButton(false);
      }
    } else if (checkMandatoryField()) { // check if in add modal
      setdisButton(true);
    } else {
      setdisButton(false);
    }
  }, [userValue]);

  // to display the value in edit page
  useEffect(() => {
    if (editState) {
      setUserValue({
        firstName: userList && Object.keys(userList).length > 0 ? userList.firstName : '',
        lastName: userList && Object.keys(userList).length > 0 ? userList.lastName : '',
        email: userList && Object.keys(userList).length > 0 ? userList.email : '',
        phone: userList && Object.keys(userList).length > 0 ? convertPhoneNo(userList.phone) : '',
        firebaseUUID: userList && Object.keys(userList).length > 0 ? userList.firebaseUUID : '',
      })
    } else {
        setUserValue({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        firebaseUUID: '',
      })
    }
  }, [userList]);

    const userOnChange = (e, names) => {
        const valueName = names;
        const target = e.target.value;
        setUserValue({ ...userValue, [valueName]: target });
    };

    // phone number onChange
    const phoneNumberChange = (e, value, names) => {
        setUserValue({ ...userValue, [names]: e });
    };


  return (
    <Modal
      open={modalClick}
      onClose={removeAllState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loadings && <CircularProgress color="success" sx={loadingStyle} />}
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          { label }
          <br />
          <br />
          <div style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: '1fr',
            gap: '0px 24px',
            gridTemplateAreas: '. .',
            justifyContent: 'center',
          }}
          >
            <div>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row' }}>
                    <TextField sx={{ width: '100%', marginRight: '8px' }} required id="standard-basic" label="First Name" value={userValue.firstName} variant="outlined" onChange={(e) => userOnChange(e, 'firstName')} />
                    <TextField sx={{ width: '100%'}}  required id="standard-basic" label="Last Name" value={userValue.lastName} variant="outlined" onChange={(e) => userOnChange(e, 'lastName')} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField required id="standard-basic" type="email" label="Email" value={userValue.email} disabled={editState && true} variant="outlined" onChange={(e) => userOnChange(e, 'email')} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <MuiPhoneNumber
                  required
                  id="outlined-basic"
                  defaultCountry="my"
                  variant="outlined"
                  value={userValue.phone}
                  onChange={(e, value) => phoneNumberChange(e, value, 'phone')}
                  autoFormat={false}
                  disabled={editState && true}
                />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField required id="standard-basic" type="email" label="Firebase UUID" value={userValue.firebaseUUID} variant="outlined" onChange={(e) => userOnChange(e, 'firebaseUUID')} />
              </FormControl>
            </div>
          </div>
        <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
            <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeAllState()}>Cancel</Button>
            <Button variant="contained" sx={{ width: 90 }} disabled={!disButton} onClick={() => handleSubmit(userValue)}>Save</Button>
        </Stack>
        </Typography>
      </Box>
    </Modal>
  );
}
