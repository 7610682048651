/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteModal from '../../components/DeleteModal';
import { APIUpdateValidateUser } from '../../utils/apiCalled';
import { getImageFirebase } from '../../utils/getImageFirebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    bgcolor: 'background.paper',
    width: '45vw',
    boxShadow: 24,
    p: 4,
  };
  
  const loadingStyle = {
    position: 'absolute',
    top: '45%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
  };
  

export default function ModalVerification({
    handleClose, modalClick, label, userList,
    setOpen, setSuccMsg, setErrMsg, setVariant,
    setModalTriggerAPICalled, modalEdit,
}) {
    // console.log('userList', modalEdit);
    const [loadings, setLoading] = useState(false);
    const [rejectBtn, setRejectBtn] = useState(false);
    const [approveBtn, setApproveBtn] = useState(false);
    const [rejectInput, setRejectInput] = useState(null);
    const [imageNRIC, setImageNRIC] = useState(null);
    const [imageProfile, setImageProfile] = useState(null);
    const [loadingImage, setLoadingImage] = useState(false);

    const getImage = (location, setState) => {
        setLoadingImage(true);
        // console.log('go heree heelloowwww driverImage------------>>>>>>>>');
        getImageFirebase(location).then((imgs) => {
          setState(imgs);
          setLoadingImage(false);
        }).catch((error) => {
        //   console.log(error);
          setLoadingImage(false);
        })
    }
  
    const removeAllState = () => {
        handleClose();
        setImageNRIC(null);
        setImageProfile(null);
    };

    useEffect(() => {
        // console.log('go here hellowwwwkajdhkajshkgj')
        if (modalEdit) {
            // console.log('go here hellow');
            getImage(userList.nricImageURI, setImageNRIC);
            getImage(userList.userImageURI, setImageProfile);
        }
    }, [modalEdit])

    // handleReject button
    const handleReject = async () => {
        try {
            const reducer = {
                phone: userList.phone,
                verificationStatus: 'UNVERIFIED',
                rejectionReason: rejectInput,
            }
            const result = await APIUpdateValidateUser(setLoading, reducer)
            if (result.status === 200) {
                setLoading(false);
                setRejectBtn(false);
                setRejectInput(null);
                setImageNRIC(null);
                setImageProfile(null);
                setOpen(true);
                setVariant('success');
                setSuccMsg('Thank you! Your submission has been sent');
                setModalTriggerAPICalled(true); // this to trigger apiCalled in POI useEffect
                removeAllState()
              } else {
                setLoading(false);
                setRejectInput(null);
                setRejectBtn(false);
                setImageNRIC(null);
                setImageProfile(null);
                setOpen(true);
                setVariant('error');
                setErrMsg('Error!, Please try again');
                removeAllState()
              }

        } catch (err) {
            setLoading(false);
            setRejectInput(null);
            setImageNRIC(null);
            setImageProfile(null);
            removeAllState()
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err}`);
        }
    };

    const handleCloseReject = () => {
        setRejectBtn(false);
        setRejectInput(null);
    }

    const handleCloseApprove = () => {
        setApproveBtn(false);
        setRejectInput(null);
    }
    // handleApprove button
    const handleApprove = async (value) => {
        try {
            const reducer = {
                phone: userList.phone,
                verificationStatus: 'VERIFIED'
            }
            // console.log('recducer apprive', reducer)
            const result = await APIUpdateValidateUser(setLoading, reducer)
            if (result.status === 200) {
                setLoading(false);
                setApproveBtn(false);
                setOpen(true);
                setVariant('success');
                setImageNRIC(null);
                setImageProfile(null);
                setSuccMsg('Thank you! Your submission has been sent');
                setModalTriggerAPICalled(true); // this to trigger apiCalled in POI useEffect
                removeAllState()
              } else {
                setLoading(false);
                setApproveBtn(false);
                setOpen(true);
                setImageNRIC(null);
                setImageProfile(null);
                setVariant('error');
                setErrMsg('Error!, Please try again');
                removeAllState()
              }

        } catch (err) {
            setLoading(false);
            // setRejectInput(null);
            setApproveBtn(false);
            setImageNRIC(null);
            setImageProfile(null);
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err}`);
            removeAllState()
        }
    };

  return (
    <Modal
        open={modalClick}
        onClose={removeAllState}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            {loadings && <CircularProgress color="success" sx={loadingStyle} />}
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            { label }
            <br />
            <br />
            <div style={{ width: '100%' }}>
                <div style={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '1fr',
                    gap: '0px 24px',
                    margin: '0px 0px 24px 0px',
                }}>
                    <a  
                        href={imageProfile}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Avatar
                            alt="NRIC"
                            src={imageProfile}
                            // src={driverImg}
                            sx={{
                                height: 200,
                                width:  '100%',
                                display: 'flex',
                                borderRadius: '8px',
                                alignItems: 'center',
                            }}
                        />
                    </a>
                    <a
                        href={imageNRIC}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                    <Avatar
                        alt="NRIC"
                        src={imageNRIC}
                        // src={driverImg}
                        sx={{
                            height: 200,
                            width: '100%',
                            display: 'flex',
                            borderRadius: '8px',
                            alignItems: 'center',
                        }}
                    />
                    </a>

                </div>
                <div>
                <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row' }}>
                    <TextField id="standard-basic" sx={{ width: '100%', marginRight: '8px' }} type="text" label="Full Name" disabled variant="outlined" value={userList && userList.fullName} />
                    {/* <TextField id="standard-basic" sx={{ width: '100%'}} type="text" label="Phone Number" disabled variant="outlined" value={userList && userList.phone} /> */}
                    <TextField sx={{ width: '100%'}} id="standard-basic" type="text" label="Nationality" disabled variant="outlined" value={userList && userList.nationality} />
                </FormControl>
                {/* <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row' }}>

                </FormControl> */}
                {
                    userList && userList.nationality !== 'Malaysia' && (
                    <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                        <TextField id="standard-basic" type="text" label="Passport Expiry" disabled variant="outlined" value={userList && userList.passportExpiry} />
                    </FormControl>
                    ) 
                }
                </div>
            </div>
            <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
                <Button variant="outlined" sx={{ width: 90 }} onClick={() => setRejectBtn(true)}>Reject</Button>
                <Button variant="contained" sx={{ width: 90 }} onClick={() => setApproveBtn(true)}>Approve</Button>
            </Stack>
        </Typography>
        <DeleteModal
          handleDelete={handleReject}
          modalDel={rejectBtn}
          setRejectInput={setRejectInput}
          isRejected
          handleDeleteClose={handleCloseReject}
          rejectInput={rejectInput}
          label="Please enter a rejection reason before proceeding."
          data={userList}
        />
        <DeleteModal
          handleDelete={handleApprove}
          modalDel={approveBtn}
          isRejected={false}
          handleDeleteClose={handleCloseApprove}
          label="Are you sure you want to approve this verification?"
          data={userList}
        />
        </Box>
    </Modal>
  )
}
