// material
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, BookingMoreMenu } from '../sections/@dashboard/user';
import ModalBookings from '../components/dashboard/ModalBookings';
import SnackBar from '../components/Snackbar';
import { APIGetAllBookings, APIRefundBookings } from '../utils/apiCalled';
import { fDateTime } from '../utils/formatTime';
import { capitalCase } from 'change-case';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array ); // query search box for trip ID and transaction ID
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'tripId', label: 'Trip ID', alignRight: false },
  { id: 'transactionId', label: 'Transaction ID', alignRight: false },
  { id: 'createdAt', label: 'Booking Time', alignRight: false },
  { id: 'zoneName', label: 'Zone', alignRight: false },
];

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '35%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
};

export default function User() {
  const [page, setPage] = useState(0);
  const [modalAdd, setModalAdd] = useState(false); // this is for the display add modal click
  const [modalEdit, setModalEdit] = useState(false);
  const [editState, setEditState] = useState(false);
  const [modalRef, setModalRef] = useState(false); // state for display delete modal
  const [modalConfirm, setModalConfirm] = useState(false); // state for display delete modal
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  // this to store the data when click one of the row
  const [detailsPage, setDetailsPage] = useState(null);
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [getBookings, setGetBookings] = useState([])
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)
  const [totalBookings, setTotalBookings] = useState(0)
  const [reasonValue, setReasonValue] = useState('');
  const [amountValue, setAmountValue] = useState(0);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // apicalled for getAdminUser
  const getAPICalled = async (pageNumber, bookingsPerPage, searchString ) => {
    try {
      const result = await APIGetAllBookings(setLoading, pageNumber + 1, bookingsPerPage, searchString);
      // console.log(result)
      if (result.status === 200) {
        const { data } = result.data;
        const totalBookings = result.data.totalBookings
        setGetBookings(data);
        setTotalBookings(totalBookings);
        setLoading(false);
      } 
      else if (result.response.data.status === 404){
        setLoading(false);
        setOpen(true);
        setGetBookings([]);
        setTotalBookings(0);
        setVariant('error');
        setErrMsg('Error! ' + result.response.data.message);
      }
      else {
        setLoading(false);
        setOpen(true);
        setGetBookings([]);
        setTotalBookings(0);
        setVariant('error');
        setErrMsg('Error! Cannot get bookings from database.');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setGetBookings([]);
      setTotalBookings(0);
      setVariant('error');
      setErrMsg('Error!', err);
    }
  };

  // API call for manual refund
  const refundAPICall = async (refundValue) => {
    setDisableConfirmButton(true)
    setDisableCancelButton(true)
    try {
      const result = await APIRefundBookings(setLoading, refundValue);
      if (result.status === 200) {
        setLoading(false);
        setDisableConfirmButton(false)
        setDisableCancelButton(false)
        setModalConfirm(false);
        setModalRef(false);
        getAPICalled(page, rowsPerPage); // Call API again to display newly updated value
        setOpen(true);
        setVariant('success');
        setSuccMsg('Booking with tripID ' + refundValue.tripId + ' is successfully refunded');
      } else {
        setLoading(false);
        setDisableConfirmButton(false)
        setDisableCancelButton(false)
        setModalConfirm(false);
        setModalRef(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(result.response.data.message)
        // setErrMsg('Unable to refund booking.'); // handle other error codes
      }
    } catch (err) {
      setLoading(false);
      setDisableConfirmButton(false)
      setDisableCancelButton(false)
      setModalConfirm(false);
      setModalRef(false);
      setOpen(true);
      setVariant('error');
      setErrMsg('Error!', err);
    }
  };

   // this to called api first page load
  useEffect(() => {
    // console.log('First page load useEffect ' + rowsPerPage)
    getAPICalled(page, rowsPerPage, filterName);
  }, [])

  // this after click submit will called back the getVehicle API
  useEffect(() => {
    if (modalTriggerAPICalled) {
      // console.log('useEffect2 ')
      getAPICalled(page, rowsPerPage, filterName);
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  // this useEffect for call the searchAsset API called. Interval for 0.6 sec
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // this to clear time out
    }
    setSearchTimeout( // to buffer the search by 0.6 second
      setTimeout(() => {
        getAPICalled(page, rowsPerPage, filterName); // call the api
      }, 600),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getBookings.map((n) => n.userName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => { // To change page when clicking left/ right button
    setPage(newPage);
    setModalTriggerAPICalled(true)
  };

  const handleChangeRowsPerPage = (event) => {  // Change number of rows to be shown on the page
    setRowsPerPage(parseInt(event.target.value, 10));
    getAPICalled(page, parseInt(event.target.value, 10), filterName);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    getAPICalled(page, rowsPerPage, filterName)
  };

  // add or edit close modal
  const handleClose = () => {
    setModalAdd(false);
  };

  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };

  const handleEditOpen = async (value) => {
    setDetailsPage(value);
    setModalEdit(true);
    setEditState(true);
  };

  const handleEditCLose = () => {
    setModalEdit(false);
    setEditState(false);
  };

  // open delete modal
  const handleRefundOpen = () => {
    setModalRef(true);
  };

  // close delete modal
  const handleRefundClose = () => {
    setModalRef(false);
  };

  // open confirm modal
  const handleConfirmOpen = (reasonString, amountInt) => {
    setModalConfirm(true);
    const string = reasonString;
    const int = amountInt;
    setReasonValue(string);
    setAmountValue(int);
  };

  // close confirm modal
  const handleConfirmClose = () => {
    setModalConfirm(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalBookings) : 0;
  const filteredUsers = applySortFilter(getBookings, getComparator(order, orderBy), filterName); //orderr = asc, orderBy = name
  return (
    <Page title="Passenger Bookings">
      <Container maxWidth="xl">
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
        />
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">Passenger Bookings</Typography>
        </Box>
        {loading && <CircularProgress color='success' sx={loadingStyle} />}
        <Card>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              // popupfilter={setOpenFilterPopUp}
              labelName="Search by Trip Id or Transaction Id"
            />
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalBookings}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort} // disabled sort for now
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    filteredUsers.map((s) => {
                      const isItemSelected = selected.indexOf(s._id) !== -1;
                      const maxAmount = s.paymentAmount - s.refundAmount - s.manualRefundAmount
                      const roundedMaxAmount = Math.round(maxAmount * 100) / 100
                      return (
                        <TableRow
                          hover
                          key={s._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" component="th" scope="row" onClick={() => handleEditOpen(s)}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {s.userName}
                            </Stack>
                          </TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{s.email}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{capitalCase(s.status.replace(/_/g, " "))}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{s.tripId}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{typeof (s.transactionId) === 'string' ? s.transactionId : 'None'}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{fDateTime(s.createdAt)}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(s)}>{s.zoneName}</TableCell>
                          <TableCell align="right">
                            <BookingMoreMenu
                              handleSeeMore={handleEditOpen}
                              bookingList={s}
                              handleRefundOpen={handleRefundOpen}
                              handleRefundClose={handleRefundClose}
                              modalRef={modalRef}
                              handleConfirmOpen={handleConfirmOpen}
                              handleConfirmClose={handleConfirmClose}
                              modalConfirm={modalConfirm}
                              handleConfirm={refundAPICall}
                              setModalTriggerAPICalled={setModalTriggerAPICalled}
                              setSuccMsg={setSuccMsg}
                              setErrMsg={setErrMsg}
                              setVariant={setVariant}
                              setOpen={setOpen}
                              tripId={s.tripId}
                              amount={amountValue}
                              reason={reasonValue}
                              loading={loading}
                              disableConfirmButton= {disableConfirmButton}
                              disableCancelButton = {disableCancelButton}
                              maxAmount={roundedMaxAmount}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            count={totalBookings}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <ModalBookings
          handleClose={editState ? handleEditCLose : handleClose}
          modalClick={editState ? modalEdit : modalAdd}
          userList={editState ? detailsPage : null}
          editState={editState}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setOpen={setOpen}
        />
      </Container>
    </Page>
  );
}