// material
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import TagsInput from '../components/TagsInput';
import MenuPopover from '../components/MenuPopover';
import ModalVehicle from '../components/dashboard/ModalVehicle';
import SnackBar from '../components/Snackbar';
import { APIDeleteVehicle, APIGetVehicle } from '../utils/apiCalled';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user && _user.length > 0 && _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const TABLE_HEAD = [
    { id: 'vehicleNumber', label: 'Vehicle Number', alignRight: false },
    { id: 'brand', label: 'Brand', alignRight: false },
    { id: 'modal', label: 'Modal', alignRight: false },
    { id: 'colour', label: 'Colour', alignRight: false },
    { id: 'capacity', label: 'Seating', alignRight: false },
    { id: 'isActive', label: 'Status', alignRight: false },
    { id: '' }
  ];

  const filterStatus = ['ACTIVE', 'INACTIVE'];
  const filterType = ['VAN', 'BUS', 'MPV'];
  
  const loadingStyle = {
    position: 'absolute',
    zIndex: 10,
    top: '35%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
  };

  export default function Vehicle() {
    const [page, setPage] = useState(0);
    const [modalAdd, setModalAdd] = useState(false); // this is for the display add modal click
    const [modalDel, setModalDel] = useState(false); // state for display delete modal
    const [modalEdit, setModalEdit] = useState(false);
    const [editState, setEditState] = useState(false);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
    // this to store the data when click one of the row
    const [detailsPage, setDetailsPage] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [variant, setVariant] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
    const [getVehicle, setGetVehicle] = useState([]);
    const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)
    const [apiCalledComponent, setApiCalledComponent] = useState(false);
    const [getFilter, setGetFilter] = useState({
        vehicleNumber: null,
        vehicleType: null,
        brand: null,
        model: null,
        colour: null,
        status: null,
        permitNumber: null,
        capacity: null,
      });
      const [dupFilter, setDupFilter] = useState({
        vehicleNumber: [],
        vehicleType: [],
        brand: [],
        model: [],
        colour: [],
        status: [],
        permitNumber: [],
        capacity: [],
      });
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const closePopup = () => {
        setOpenFilterPopUp(null);
    };

    const clearFilterButton = () => {
        // console.log('go here to clear the filter button');
        setGetFilter({
            vehicleNumber: null,
            vehicleType: null,
            brand: null,
            model: null,
            colour: null,
            status: null,
            permitNumber: null,
            capacity: null,
        });
        setDupFilter({
            vehicleNumber: [],
            vehicleType: [],
            brand: [],
            model: [],
            colour: [],
            status: [],
            permitNumber: [],
            capacity: [],
        });
        setApiCalledComponent(true);  // this to called getAPI if buttonFiltered.
      };
  
    const getAPICalled = async (filter) => {
      try {
        const result = await APIGetVehicle(setLoading, filter);
        // console.log('result', result);
        if (result.status === 200) {
          const { data } = result;
          setGetVehicle(data);
          setLoading(false);
        } else {
          setLoading(false);
          setOpen(true);
          setVariant('error');
          setErrMsg('Error!, Please try again');
        }
      } catch (err) {
        // console.log('GO HERE IF ERROR', err);
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!', err);
      }
    };
  
    // this to called api first page load
    useEffect(() => {
      getAPICalled(getFilter);
    }, [])

    // this to called API after click clear button
    useEffect(() => {
      if(apiCalledComponent) {
        getAPICalled(getFilter)
        setApiCalledComponent(false);
      }
    }, [apiCalledComponent])
    
    // this after click submit will called back the getVehicle API
    useEffect(() => {
      if (modalTriggerAPICalled) {
        getAPICalled(getFilter);
        setModalTriggerAPICalled(false)
      }
    }, [modalTriggerAPICalled])
  
    const handleSelectAllClick = (event) => {
    //   if (event.target.checked) {
    //     const newSelecteds = getDriver.map((n) => n.name);
    //     setSelected(newSelecteds);
    //     return;
    //   }
    //   setSelected([]);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleFilterByName = (event) => {
      setFilterName(event.target.value);
    };
  
    // add or edit open modal
    const handleOpen = () => {
      setModalAdd(true);
    };
  
    // add or edit close modal
    const handleClose = () => {
      setModalAdd(false);
    };
  
    // snackbarClose
    const handleSnackBarClose = () => {
      setOpen(false);
    };
  
  
    const handleEditOpen = async (value) => {
          // console.log('vaue', value);
          setDetailsPage(value);
          setModalEdit(true);
          setEditState(true);
    };
  
    const handleEditCLose = () => {
      setModalEdit(false);
      setEditState(false);
    };
  
      // open delete modal
      const handleDeleteOpen = () => {
        setModalDel(true);
      };
    
      // close delete modal
      const handleDeleteClose = () => {
        setModalDel(false);
      };
  
      const handleDelete = async (data) => {
        try {
          const result = await APIDeleteVehicle(setLoading, data._id);
          if (result.status === 200) {
            setLoading(false);
            setModalDel(false);
            setOpen(true);
            setVariant('success');
            setSuccMsg('Succesfully delete the records!');
            setModalTriggerAPICalled(true);
          } else {
            setLoading(false);
            setModalDel(false);
            setOpen(true);
            setVariant('error');
            setErrMsg('Error!, Please try again');
          }
        } catch (err) {
          setLoading(false);
          setModalDel(false);
          setOpen(true);
          setVariant('error');
          setErrMsg('Error!, Please try again');
        }
      };
    
    const filterButton = () => {
      getAPICalled(getFilter);
    };
    
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getVehicle.length) : 0;
  
    const filteredUsers = applySortFilter(getVehicle, getComparator(order, orderBy), filterName);
  
    const isUserNotFound = filteredUsers.length === 0;
    return (
      <Page title="Drivers Management">
        <Container maxWidth="xl">
          <SnackBar
            open={open}
            alert={variant}
            handleClose={handleSnackBarClose}
            errorMsg={errMsg}
            successMsg={succMsg}
          />
          <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4">Vehicle Management</Typography>
            <Button
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => handleOpen()}
              >
                New Vehicle
              </Button>
          </Box>
          {loading && <CircularProgress color='success' sx={loadingStyle} /> }
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              popupfilter={setOpenFilterPopUp}
              labelName="Search Vehicle No.."
              displayFilter
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getVehicle.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                <MenuPopover open={Boolean(openFilterPopUp)} anchorEl={openFilterPopUp} onClose={closePopup} styleWidth={500}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 1 }}>Filter By</Typography>
                        <TagsInput fullWidth variant="outlined" id="vehicleNumber" name="vehicleNumber" placeholder="add tags" label="Vehicle Number" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="vehicleNumber" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="brand" name="brand" placeholder="add tags" label="Brand" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="brand" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="model" name="model" placeholder="add tags" label="Model" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="model" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="colour" name="colour" placeholder="add tags" label="Colour" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="colour" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="status" name="status" placeholder="add tags" label="Status" type="autocomplete" selectList={filterStatus} dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="status" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="vehicleType" name="vehicleType" placeholder="add tags" label="Vehicle Type" type="autocomplete" selectList={filterType} dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="vehicleType" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="permitNumber" name="permitNumber" placeholder="add tags" type="text" label="Permit Number" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="permitNumber" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                        <TagsInput fullWidth variant="outlined" id="capacity" name="capacity" placeholder="add tags" label="Seating" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="capacity" setGetFilter={setGetFilter} getFilter={getFilter} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                        <Button variant="outlined" sx={{ width: 110, marginRight: '8px' }} component={RouterLink} to="#" onClick={() => clearFilterButton()}>
                        Clear Filter
                        </Button>
                        <Button variant="contained" sx={{ width: 110 }} component={RouterLink} to="#" onClick={() => filterButton()}>
                        Filter
                        </Button>
                    </Box>
                </MenuPopover>
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { _id, vehicleNumber, brand, model, colour, capacity, status } = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;
  
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left" component="th" scope="row" onClick={() => handleEditOpen(row)}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                  {vehicleNumber}
                              </Stack>
                            </TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{brand}</TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{model}</TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{colour}</TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{capacity}</TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>
                              <Label
                                variant="ghost"
                                color={(status === 'INACTIVE' && 'error') || 'success'}
                              >
                                {sentenceCase(status)}
                              </Label>
                            </TableCell>
  
                            <TableCell align="right">
                              <UserMoreMenu 
                                handleEditOpen={handleEditOpen}
                                driverList={row}
                                handleDeleteOpen={handleDeleteOpen}
                                handleDeleteClose={handleDeleteClose}
                                modalDel={modalDel}
                                handleDelete={handleDelete}
                                setModalTriggerAPICalled={setModalTriggerAPICalled}
                                setSuccMsg={setSuccMsg}
                                setErrMsg={setErrMsg}
                                setVariant={setVariant}
                                setOpen={setOpen}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
  
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getVehicle.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <ModalVehicle 
            handleClose={editState ? handleEditCLose : handleClose}
            modalClick={editState ? modalEdit : modalAdd}
            label={editState ? 'Edit Vehicle' : 'Add Vehicle'}
            vehicleList={editState ? detailsPage : null}
            editState={editState}
            setModalTriggerAPICalled={setModalTriggerAPICalled}
            setSuccMsg={setSuccMsg}
            setErrMsg={setErrMsg}
            setVariant={setVariant}
            setOpen={setOpen}
          />
        </Container>
      </Page>
    );
  }