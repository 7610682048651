// to check mandatory field
export const AllMandatoryFilled = (requiredField, input) => requiredField.every((a) => {
    if (Object.keys(input).includes(a) && input[a]) {
      if (Array.isArray(input[a])) {
        return input[a].length > 0;
      }
      return true;
    }
    return false;
  });
  
  // requiredField
  export const requiredField = {
    driver: ['firstName', 'lastName', 'email', 'phone', 'status', 'nricNo', 'nricImage', 'driverImage', 'password', 'confirmPassword'],
    vehicle: ['vehicleNumber', 'brand', 'colour', 'model', 'vehicleType', 'permitNumber', 'permitValidity', 'status', 'capacity'],
    user: ['firstName', 'lastName', 'phone', 'email', 'firebaseUUID'],
  };
  