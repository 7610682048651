// material
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import ModalDriver from '../components/dashboard/ModalDriver'
import SnackBar from '../components/Snackbar';
import { APIGetDriver, APIDeleteDriver } from '../utils/apiCalled';
//

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) =>  _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'rating', label: 'Driver Rating', alignRight: false },
  { id: '' }
];

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '35%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
};

export default function Driver() {
  const [page, setPage] = useState(0);
  const [modalAdd, setModalAdd] = useState(false); // this is for the display add modal click
  const [modalDel, setModalDel] = useState(false); // state for display delete modal
  const [modalEdit, setModalEdit] = useState(false);
  const [editState, setEditState] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // this to store the data when click one of the row
  const [detailsPage, setDetailsPage] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [getDriver, setGetDriver] = useState([]);
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getAPICalled = async () => {
    try {
      const result = await APIGetDriver(setLoading);
      if (result.status === 200) {
        const { data } = result;
        setGetDriver(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      // console.log('GO HERE IF ERROR', err);
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg('Error!', err);
    }
  };

  useEffect(() => {
    getAPICalled();
  }, [])

  useEffect(() => {
    if (modalTriggerAPICalled) {
      getAPICalled();
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getDriver.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // add or edit open modal
  const handleOpen = () => {
    setModalAdd(true);
  };

  // add or edit close modal
  const handleClose = () => {
    setModalAdd(false);
  };

  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };


  const handleEditOpen = async (value) => {
        setDetailsPage(value);
        setModalEdit(true);
        setEditState(true);
  };

  const handleEditCLose = () => {
    setModalEdit(false);
    setEditState(false);
  };

    // open delete modal
    const handleDeleteOpen = () => {
      setModalDel(true);
    };
  
    // close delete modal
    const handleDeleteClose = () => {
      setModalDel(false);
    };

    const handleDelete = async (data) => {
      try {
        const result = await APIDeleteDriver(setLoading, data._id);
        if (result.status === 200) {
          setLoading(false);
          setModalDel(false);
          setOpen(true);
          setVariant('success');
          setSuccMsg('Succesfully delete the records!');
          setModalTriggerAPICalled(true);
        } else {
          setLoading(false);
          setModalDel(false);
          setOpen(true);
          setVariant('error');
          setErrMsg('Error!, Please try again');
        }
      } catch (err) {
        setLoading(false);
        setModalDel(false);
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!, Please try again');
      }
    };

  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getDriver.length) : 0;

  const filteredUsers = applySortFilter(getDriver, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Drivers Management">
      <Container maxWidth="xl">
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
        />
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">Driver Management</Typography>
          <Button
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => handleOpen()}
            >
              New Driver
            </Button>
        </Box>
        {loading && <CircularProgress color='success' sx={loadingStyle} /> }
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            labelName="Search First name"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={getDriver.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, firstName, lastName, email, status, evpNo, rating } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" component="th" scope="row" onClick={() => handleEditOpen(row)}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {firstName}
                            </Stack>
                          </TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(row)}>{lastName}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(row)}>{email}</TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(row)}>
                            <Label
                              variant="ghost"
                              color={(status === 'INACTIVE' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left" onClick={() => handleEditOpen(row)}>{rating && parseFloat(rating).toFixed(1)}</TableCell>

                          <TableCell align="right">
                            <UserMoreMenu 
                              handleEditOpen={handleEditOpen}
                              driverList={row}
                              handleDeleteOpen={handleDeleteOpen}
                              handleDeleteClose={handleDeleteClose}
                              modalDel={modalDel}
                              handleDelete={handleDelete}
                              setModalTriggerAPICalled={setModalTriggerAPICalled}
                              setSuccMsg={setSuccMsg}
                              setErrMsg={setErrMsg}
                              setVariant={setVariant}
                              setOpen={setOpen}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={getDriver.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <ModalDriver 
          handleClose={editState ? handleEditCLose : handleClose}
          modalClick={editState ? modalEdit : modalAdd}
          label={editState ? 'Edit Driver' : 'Add Driver'}
          driverList={editState ? detailsPage : null}
          editState={editState}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setOpen={setOpen}
        />
      </Container>
    </Page>
  );
}
