import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  width: '60vw',
  boxShadow: 24,
  p: 4,
};

export default function ModalRefund({
  handleRefundClose, modalRef, tripId, isRejected, setRejectInput, rejectInput,handleConfirmOpen, maxAmount
}) {
  const [disabledButton, setDisabledButton] = useState(true);
  const [reasonValue, setReasonValue] = useState('');
  const [amountValue, setAmountValue] = useState(0);
  // disable button
  useEffect(() => {
    if ((reasonValue == null || reasonValue.trim() === '') || (amountValue == null || amountValue <= 0 ||  isNaN(amountValue) || amountValue > maxAmount)) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [reasonValue, amountValue])

  const reasonOnChange = (e, value) => {
    // console.log(e.trargwe)
    const target = e.target.value;
    setReasonValue(target);
  };

  const amountOnChange = (e) => {
    const target = e.target.value;
    setAmountValue(target)
  };

  // Function below is to fix bug where the input letter 'S' and 'R' is will deselect the modal in reason TextField
  const onKeyDown = (e:KeyboardEvent ) => {
     e.stopPropagation();
  }

  const twoDigit = (+maxAmount).toFixed(2).toString();
  return (
    <Modal
      open={modalRef}
      onClose={handleRefundClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" sx={{ fontSize: 18, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          Refund Booking For Trip ID {tripId}
          <Typography id="modal-modal-title" sx={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <br />
            Please enter refund amount. Max amount that can be refunded is MYR {twoDigit}
          </Typography>

          <FormControl sx={{ marginTop: '8px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <TextField sx={{ width: '100%' }} required id="outlined-required" onChange={(e) => amountOnChange(e)} />
          </FormControl>
          <Typography id="modal-modal-title" sx={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <br />
            Please enter reason for the refund (MAX 300 characters) .
          </Typography>

          <FormControl sx={{ marginTop: '8px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <TextField sx={{ width: '100%' }} 
              required
              id="outlined-required"
              // label="Required" // Also to fix the bug where letters 'S' and 'R' deselects modal
              // variant="filled"
              value={reasonValue}
              onKeyDown={onKeyDown}
              onChange={(e) => reasonOnChange(e)} 
            />
          </FormControl>
        </Typography>
        <Stack spacing={20} direction="row" alignItems="center" sx={{ marginTop: '16px', }}>
          <Button variant="outlined" sx={{ width: '20%' }} onClick={() => handleRefundClose()}>Cancel</Button>
          <Button variant="contained" sx={{ width: '20%' }} disabled={!disabledButton} onClick={() => handleConfirmOpen(reasonValue, amountValue)}>Continue</Button>
        </Stack>
      </Box>
    </Modal>

  );
}