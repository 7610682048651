/* eslint-disable max-len */
/* eslint-disable quote-props */
import axios from 'axios';

const url = process.env.REACT_APP_ENDPOINT_DEV;
const apiKey = process.env.REACT_APP_API_KEY_DEV;

export const APIRefundBookings = async (setLoading, refundData) => { // tripId, amount, refundReason, adminUserId
  setLoading(true);
  const result = await axios.post(`${url}/payment/manualRefund`, refundData, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
};

export const APIGetAllBookings = async (setLoading, pageNumber, bookingsPerPage, searchString) => {
  setLoading(true);
  const result = await axios.get(`${url}/bookings`, {
    headers: {
      'x-api-key': apiKey,
    },
    params: {
      pageNumber,
      bookingsPerPage,
      searchString,
      status: 'completed,cancelled,failed_to_board,timed_out',
    }
  }).catch((error) => error);
  return result;
};

// called APIGetDriver
export const APIGetDriver = async (setLoading) => {
    setLoading(true);
    const result = await axios.get(`${url}/drivers`, {
      headers: {
        'x-api-key': apiKey,
      },
    }).catch((error) => error);
    return result;
};

export const APIGetVehicle = async (setLoading, params) => {
    setLoading(true)
    const result = await axios.get(`${url}/vehicle`, {
      headers: {
        'x-api-key' : apiKey
      },
       params: {
        vehicleNumber: params && params.vehicleNumber,
        brand: params && params.brand,
        model: params && params.model,
        colour: params && params.colour,
        vehicleType: params && params.vehicleType,
        permitNumber: params && params.permitNumber,
        status: params && params.status,
        capacity: params && params.capacity,
      }
    }).catch((error) => error);
    return result;
};

export const APIGetAdminUser = async (setLoading, email) => {
  setLoading(true);
  const result = await axios.get(`${url}/adminUsers`, {
    headers: {
      'x-api-key' : apiKey
    },
    params: {
      email: email,
    }
  }).catch((error) => error);
  return result;
}

//called APIGetAdminUserByFirebaseUUID
export const APIGetAdminUserByFirebaseUUID = async (setLoading, firebaseUUID) => {
  setLoading(true);
  const result = await axios.get(`${url}/adminUser/${firebaseUUID}`, {
    headers: {
      'x-api-key': apiKey,
    }
  }).catch((error) => error);
  return result;
}

// called CreateDriver
export const APICreateDriver = async (setLoading, driverData) => {
    setLoading(true);
    const result = await axios.post(`${url}/driver`, driverData, {
      headers: {
        'x-api-key': apiKey,
      },
    }).catch((error) => error);
    return result;
};

export const APICreateVehicle = async (setLoading, vehicleData) => {
  setLoading(true);
  const result = await axios.post(`${url}/vehicle`, vehicleData, {
    headers: {
      'x-api-key' : apiKey,
    }
  }).catch((error) => error);
  return result;
}

export const APICreateUser = async (setLoading, userData) => {
  setLoading(true);
  const result = await axios.post(`${url}/adminUser`, userData, {
    headers: {
      'x-api-key' : apiKey,
    }
  }).catch((error) => error);
  return result;
};

// called Update
export const APIUpdateDriver = async (setLoading, driverId, driverData) => {
    setLoading(true);
    const result = await axios.patch(`${url}/driver/${driverId}`, driverData, {
      headers: {
        'x-api-key': apiKey,
      },
    }).catch((error) => error);
    return result;
};

export const APIUpdateVehicle = async (setLoading, vehicleId, vehicleData) => {
  setLoading(true);
  const result = await axios.patch(`${url}/vehicle/${vehicleId}`, vehicleData, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
}

export const APIUpdateUser = async (setLoading, userId, userData) => {
  setLoading(true);
  const result = await axios.patch(`${url}/adminUser/${userId}`, userData, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
}

export const APIDeleteDriver = async (setLoading, driverId) => {
  setLoading(true);
  const result = await axios.delete(`${url}/driver/${driverId}`, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
};

export const APIDeleteVehicle = async (setLoading, vehicleId) => {
  setLoading(true);
  const result = await axios.delete(`${url}/vehicle/${vehicleId}`, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
}

export const APIDeleteUser = async (setLoading, userId) => {
  setLoading(true);
  const result = await axios.delete(`${url}/adminUser/${userId}`, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
}

export const APIGetUsers = async (setLoading, status) => {
  setLoading(true);
  const result = await axios.get(`${url}/users`, {
    headers: {
      'x-api-key' : apiKey
    },
    params: {
      verificationStatus: status
    }
  }).catch((error) => error);
  // console.log('result', result);
  return result;
}

export const APIUpdateValidateUser = async (setLoading, userData) => {
  setLoading(true);
  const result = await axios.patch(`${url}/validateUser/`, userData, {
    headers: {
      'x-api-key': apiKey,
    },
  }).catch((error) => error);
  return result;
}