/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import FileBase64 from 'react-file-base64';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import MuiPhoneNumber from 'material-ui-phone-number';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';

import { AllMandatoryFilled, requiredField } from '../../utils/config';
import { APICreateDriver, APIUpdateDriver } from '../../utils/apiCalled';
import { getImageFirebase } from '../../utils/getImageFirebase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  width: '75vw',
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  transform: 'translate(-50%, -50%)',
};

export default function ModalDriver({
  handleClose, modalClick, label, driverList, editState,
  setOpen, setSuccMsg, setErrMsg, setVariant, setTempNextPage, setPage,
  setModalTriggerAPICalled,
}) {
//   const token = Cookies.get('token');
//   const userType = Cookies.get('userType');
  // convert phone no into integer
  // console.log(driverList);
  const convertPhoneNo = (value) => {
    let textValue;
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    const isSpecialCharsPresent = specialChars.split('').some(char => 
      value.includes(char)) // true if present and false if not
    
    if (isSpecialCharsPresent) {
      textValue = value;
    } else {
      textValue = `+${value}`;
    }
    return textValue;
  }
  const getImageDriver = (location, setState) => {
      // console.log('go heree heelloowwww driverImage------------>>>>>>>>');
      getImageFirebase(location).then((imgs) => {
        setState(imgs);
      }).catch((error) => {
        // console.log(error);
      })
  }

  const getImageNRIC = (location, setState) => {
      // console.log('go heree heelloowwww nricImage------------>>>>>>>>');
      getImageFirebase(location).then((imgs) => {
        setState(imgs);
      }).catch((error) => {
        // console.log(error);
      })
  }

  const [driverImg, setDriverImg] = useState(null);
  const [nricImg, setNricImg] = useState(null);
  const [clickOnce, setClickOnce] = useState({
    dImg: false,
    icImg: false,
  });
  const [driverValue, setDriverValue] = useState({
    firstName: driverList && Object.keys(driverList).length > 0 ? driverList.firstName : '',
    lastName: driverList && Object.keys(driverList).length > 0 ? driverList.lastName : '',
    email: driverList && Object.keys(driverList).length > 0 ? driverList.email : '',
    phone: driverList && Object.keys(driverList).length > 0 ? convertPhoneNo(driverList.phone) : '',
    status: driverList && Object.keys(driverList).length > 0 ? driverList.status : '',
    nricNo: driverList && Object.keys(driverList).length > 0 ? driverList.nricNo : '',
    driverImage: driverList && Object.keys(driverList).length > 0 ? driverList.driverImageURI : '',
    nricImage: driverList && Object.keys(driverList).length > 0 ? driverList.nricImageURI : '',
    password: driverList && Object.keys(driverList).length > 0 ? 'dummies' : '',
    confirmPassword: driverList && Object.keys(driverList).length > 0 ? 'dummies' : '',
  });
  const [disButton, setdisButton] = useState(true);
  const [loadings, setLoading] = useState(false);
  const [newUpload, setNewUpload] = useState(false);
  const [newUploadProfile, setNewUploadProfile] = useState(false)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [errPassword, setErrPassword] = useState({
    password: false,
    confirmPassword: false,
  })


  // console.log('HOLAAA IMAGE', getImage("https://storage.googleapis.com/mobiliti-engine-dev.appspot.com/drivers/62a9fb69b2cfa0d1c9c16402.jpg"))
  const imgTimestamp = new Date().valueOf();

  const driverOnchange = (e, names) => {
    const valueName = names;
    const target = e.target.value;
    setDriverValue({ ...driverValue, [valueName]: target });
  };

  useEffect(() => {
    if(driverValue.password !== driverValue.confirmPassword) {
      setErrPassword({ ...errPassword, password: true, confirmPassword: true });
    } else {
      setErrPassword({ ...errPassword, password: false, confirmPassword: false });
    }
  }, [driverValue])

  const removeAllState = () => {
    setDriverValue({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      status: '',
      nricNo: '',
      driverImage: '',
      nricImage: '',
      swatInternalId: '',
    })
    setNewUpload(false);
    setNewUploadProfile(false);
    setDriverImg(null);
    setNricImg(null);
    setClickOnce({
      dImg: false,
      icImg: false,
    })
    handleClose();
  };
  // phone number onChange
  const phoneNumberChange = (e, value, names) => {
    setDriverValue({ ...driverValue, [names]: e });
  };

  const changeDisplayPass = (name) => {
    setShowPassword({...showPassword, [name]: !showPassword[name] });
  };
  // console.log('driverValue', driverValue);

  // to called the add / edit api
  const apiAddEditCalled = async (value, id) => {
    // console.log('value', value);
    try {
      const result = editState
        ? await APIUpdateDriver(setLoading, id, value)
        : await APICreateDriver(setLoading, value);
      if (result.status === 200) {
        setLoading(false);
        removeAllState();
        setOpen(true);
        setVariant('success');
        setSuccMsg('Thank you! Your submission has been sent');
        setModalTriggerAPICalled(true); // this to trigger apiCalled in POI useEffect
      } else {
        setLoading(false);
        removeAllState();
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      removeAllState();
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
    }
  };

  // first parameter object are the state and second parameter
  // "argValue" are from driverValue, "driverList" are from the api result
  const arrangeInput = (argValue, listDriver) => {
    const input = {};
    // if (argValue.firstName !== listDriver.firstName) {
    //   input.firstName = argValue.firstName;
    // }
    input.firstName = argValue.firstName;
    // if (argValue.lastName !== listDriver.lastName) {
    //   input.lastName = argValue.lastName;
    // }
    input.lastName = argValue.lastName;
    if (argValue.email !== listDriver.email) {
      input.email = argValue.email;
    }
    if (argValue.password !== 'dummies') {
      input.password = argValue.password
    }
    if (argValue.phone !== listDriver.phone) {
      input.phone = argValue.phone;
    }
    // this to check poi list image url ---------------------------------
    if (argValue.status !== listDriver.status) {
      input.status = argValue.status;
    }
    // -----------------------------------------------------------------------
    if (argValue.nricNo !== listDriver.nricNo) {
      input.nricNo = argValue.nricNo
    }
    if (argValue.nricImage !== listDriver.nricImageURI) {
      input.nricImage = argValue.nricImage
    }
    if (argValue.driverImage !== listDriver.driverImageURI){
      input.driverImage = argValue.driverImage
    }
    return input;
  };

  // handlesubmit button
  const handleSubmit = (value) => {
    // console.log(value);
    if (editState) {
      const input = arrangeInput(value, driverList)
      const { _id } = driverList;
      apiAddEditCalled(input, _id)
    } else {
      apiAddEditCalled(value, false);
    }
  };

  // console.log(driverValue);

  // onChange uploadImage
  const uploadImage = async (image) => {
    if (image[0].type.includes('image')) {
      const { base64 } = image[0];
      setNewUploadProfile(true);
      setDriverValue({ ...driverValue, driverImage: base64 });
      setDriverImg(base64);
      setClickOnce({ ...clickOnce, dImg: true });
    } else {
      setOpen(true); // snackbar open state
      setVariant('error'); // snackbar severity state
      setErrMsg('Please select Image file'); // snackbar label message state
      setNewUploadProfile(false);
      setDriverValue({ ...driverValue, driverImage: '' });
      setDriverImg(null);
      setClickOnce({ ...clickOnce, dImg: false });
    }
  };

  // console.log({
  //   driverImg, nricImg, driverValue
  // })

  const uploadImageNric = async (image) => {
    if (image[0].type.includes('image')) {
      const { base64 } = image[0];
      setNewUpload(true);
      setDriverValue({ ...driverValue, nricImage: base64 });
      setNricImg(base64);
      setClickOnce({ ...clickOnce, icImg: true });
    } else {
      setOpen(true); // snackbar open state
      setVariant('error'); // snackbar severity state
      setErrMsg('Please select Image file'); // snackbar label message state
      setNewUpload(false);
      setDriverValue({ ...driverValue, nricImage: '' });
      setNricImg(null);
      setClickOnce({ ...clickOnce, icImg: false });
    }
  }

  // checking for mandatory field in edit module. Btn will disable if there is no changes.
  const checkDetailsChanges = () => {
    const inputChecking = arrangeInput(driverValue, driverList);
    const checking = Object.keys(inputChecking).length > 0;
    return checking;
  };

  // checking value for mandatory field
  const checkValidationPoi = () => {
    const checkValidation = AllMandatoryFilled(requiredField.driver, driverValue);
    return checkValidation;
  };

  // const checkingPassword = () => {
  //   const passwordChecking = 
  // }

  // console.log('driverModule', driverValue);
  

  // && checkDetailsChanges()
  // this to do the button submit validation (disable button)
  useEffect(() => {
    if (editState) { // check if it in edit modal
      if (checkValidationPoi() && checkDetailsChanges()) {
        setdisButton(true);
      } else {
        setdisButton(false);
      }
    } else if (checkValidationPoi()) { // check if in add modal
      setdisButton(true);
    } else {
      setdisButton(false);
    }
  }, [driverValue]);

  // to display the value in edit page
  useEffect(() => {
    if (editState) {
      setDriverValue({
        firstName: driverList && Object.keys(driverList).length > 0 ? driverList.firstName : '',
        lastName: driverList && Object.keys(driverList).length > 0 ? driverList.lastName : '',
        email: driverList && Object.keys(driverList).length > 0 ? driverList.email : '',
        phone: driverList && Object.keys(driverList).length > 0 ? convertPhoneNo(driverList.phone) : '',
        status: driverList && Object.keys(driverList).length > 0 ? driverList.status : '',
        nricNo: driverList && Object.keys(driverList).length > 0 ? driverList.nricNo : '',
        driverImage: driverList && Object.keys(driverList).length > 0 ? driverList.driverImageURI : '',
        nricImage: driverList && Object.keys(driverList).length > 0 ? driverList.nricImageURI : '',
        password: driverList && Object.keys(driverList).length > 0 ? 'dummies' : '',
        confirmPassword: driverList && Object.keys(driverList).length > 0 ? 'dummies' : '',
      })
      if (driverList.driverImageURI) {
        getImageDriver(driverList.driverImageURI, setDriverImg);
      }
  
      if (driverList.nricImageURI) {
        getImageDriver(driverList.nricImageURI, setNricImg);
      }
    } else {
      setDriverValue({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        status: '',
        nricNo: '',
        driverImage: '',
        nricImage: '',
        password: '',
        confirmPassword: '',
      })
      setDriverImg(null);
      setNricImg(null);
    }
  }, [driverList]);

  // console.log('driverValue', clickOnce);

  const userOnchange = (e, names) => {
    const targetValue = e.target.value; // get the value
    const fieldName = names; // fieldName
    setDriverValue({ ...driverValue, [fieldName]: targetValue });
  };

  // console.log('driverListWOAH', driverValue);

  return (
    <Modal
      open={modalClick}
      onClose={removeAllState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loadings && <CircularProgress color="success" sx={loadingStyle} />}
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          { label }
          <br />
          <br />
          <div style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: '1fr 2fr 2fr',
            gap: '0px 24px',
            gridTemplateAreas: '. .',
            justifyContent: 'center',
          }}
          >
            <div>
              <div>
              {
                driverImg ? (
                  <a
                  href={newUploadProfile ? driverValue.driverImage : driverImg}
                  // href={driverImg}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Avatar
                    alt="poiImage"
                    src={newUploadProfile ? driverValue.driverImage : driverImg}
                    // src={driverImg}
                    sx={{
                      height: 140,
                      width: '60%',
                      display: 'flex',
                      borderRadius: '60%',
                      alignItems: 'center',
                    }}
                  />
                </a>
                ) : (
                  <div>
                    <div style={{
                      height: '140px',
                      width: '60%',
                      borderRadius: '60%',
                      borderStyle: 'dashed',
                      borderWidth: '1px',
                      borderColor: 'rgba(145, 158, 171, 0.32)',
                      fontSize: '12px',
                      color: 'rgba(145, 158, 171, 0.8)',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '140px',
                    }}
                    >
                      Driver's Image
                    </div>
                  </div>
                )
              }
              <div style={{ margin: '8px 0px 8px 0px' }}>
                <FileBase64 multiple onDone={uploadImage} />
              </div>
              </div>
              <div>
                {
                  nricImg ? (
                    <a
                    href={newUpload ? driverValue.nricImage : nricImg}
                    // href={nricImg}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Avatar
                      alt="poiImage"
                      src={newUpload ? driverValue.nricImage : nricImg}
                      // src={nricImg}
                      variant="square"
                      sx={{
                        height: 140,
                        width: '100%',
                        display: 'flex',
                        borderRadius: '8px',
                        alignItems: 'center',
                      }}
                    />
                  </a>
                  ) : (
                    <div style={{
                      height: '140px',
                      width: '100%',
                      borderRadius: '8px',
                      borderStyle: 'dashed',
                      borderWidth: '1px',
                      borderColor: 'rgba(145, 158, 171, 0.32)',
                      fontSize: '12px',
                      color: 'rgba(145, 158, 171, 0.8)',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '140px',
                    }}
                    >
                      NRIC Image
                    </div>
                  )
                }
              <div style={{ margin: '8px 0px 8px 0px' }}>
                <FileBase64 multiple onDone={uploadImageNric} />
              </div>
              </div>
            </div>
            <div>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection:'row' }}>
                    <TextField sx={{ width: '100%', marginRight: '8px' }} required id="standard-basic" label="First Name" value={driverValue.firstName} variant="outlined" onChange={(e) => driverOnchange(e, 'firstName')} />
                    <TextField sx={{ width: '100%'}}  required id="standard-basic" label="Last Name" value={driverValue.lastName} variant="outlined" onChange={(e) => driverOnchange(e, 'lastName')} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField required id="standard-basic" type="email" label="Email" value={driverValue.email} disabled={editState && true} variant="outlined" onChange={(e) => driverOnchange(e, 'email')} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField
                    required
                    id="standard-basic"
                    type={showPassword.password ? "text" : "password"}
                    label="Password"
                    variant="outlined"
                    onChange={(e) => driverOnchange(e, 'password')}
                    value={driverValue.password}
                    error={errPassword.password}
                    helperText={ errPassword.password ? 'The password confirmation does not match' : '' }
                  />
                  {
                    showPassword.password ? (
                      <div style={!errPassword.password ? {
                        margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', bottom: '8px', cursor: 'pointer',
                      } : { margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', top: '20%', cursor: 'pointer' }
                    }
                      >
                        <RemoveRedEyeIcon onClick={() => changeDisplayPass('password')} />
                      </div>
                    ) : (
                      <div style={!errPassword.password ? {
                        margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', bottom: '8px', cursor: 'pointer',
                      } :  { margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', top: '20%', cursor: 'pointer' }
                    }
                      >
                        <VisibilityOffIcon onClick={() => changeDisplayPass('password')} />
                      </div>
                    )
                  }
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField
                    required
                    id="standard-basic"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    variant="outlined"
                    onChange={(e) => driverOnchange(e, 'confirmPassword')}
                    value={driverValue.confirmPassword}
                    style={{ width: '100%'}}
                    error={errPassword.confirmPassword}
                    helperText={ errPassword.confirmPassword ? 'The password confirmation does not match' : '' }
                  />
                  {
                    showPassword.confirmPassword ? (
                      <div style={!errPassword.confirmPassword ? {
                        margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', bottom: '8px', cursor: 'pointer',
                      } : { margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', top: '20%', cursor: 'pointer' }
                    }
                      >
                        <RemoveRedEyeIcon onClick={() => changeDisplayPass('confirmPassword')} />
                      </div>
                    ) : (
                      <div style={!errPassword.confirmPassword ? {
                        margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', bottom: '8px', cursor: 'pointer',
                      } : { margin: '0px 0px 0px 8px', position: 'absolute', right: '15px', top: '20%', cursor: 'pointer' }
                    }
                      >
                        <VisibilityOffIcon onClick={() => changeDisplayPass('confirmPassword')} />
                      </div>
                    )
                  }
              </FormControl>
              <FormControl
                required
                variant="outlined"
                sx={{ marginBottom: '16px', width: '100%' }}
              >
                <InputLabel variant="outlined" htmlFor="uncontrolled-native">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={driverValue.status}
                  onChange={(e) => userOnchange(e, 'status')}
                  label="Status"
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
             <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <MuiPhoneNumber
                  required
                  id="outlined-basic"
                  defaultCountry="my"
                  variant="outlined"
                  disabled={editState && true}
                  value={driverValue.phone}
                  onChange={(e, value) => phoneNumberChange(e, value, 'phone')}
                  autoFormat={false}
                />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%', display: 'flex', flexDirection:'row'  }}>
                <TextField required id="standard-basic" sx={{ width: '100%', marginRight: '8px' }} value={driverValue.nricNo} label="NRIC No" variant="outlined" onChange={(e) => driverOnchange(e, 'nricNo')} />
              </FormControl>
              {
                editState && (
                  <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                    <TextField required id="standard-basic" label="Swat Internal ID" disabled value={driverList.swatInternalId} variant="outlined" onChange={(e) => driverOnchange(e, 'swatInternalId')} />
                  </FormControl>
                )
              }
            </div>
          </div>
        <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
            <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeAllState()}>Cancel</Button>
            <Button variant="contained" sx={{ width: 90 }} disabled={!disButton} onClick={() => handleSubmit(driverValue)}>Save</Button>
        </Stack>
        </Typography>
      </Box>
    </Modal>
  );
}
