/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AllMandatoryFilled, requiredField } from '../../utils/config';
import { APIGetAllBookings } from '../../utils/apiCalled';
import { fDateTime } from '../../utils/formatTime';
import { capitalCase } from 'change-case';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  width: '60vw',
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  transform: 'translate(-50%, -50%)',
};

export default function ModalBookings({
  handleClose, modalClick, label, userList, editState,
  setOpen, setSuccMsg, setErrMsg, setVariant,
}) {


  const [userValue, setUserValue] = useState({
    userName: userList && Object.keys(userList).length > 0 ? userList.userName : '',
    status: userList && Object.keys(userList).length > 0 ? userList.status : '',
    email: userList && Object.keys(userList).length > 0 ? userList.email : '',
    tripId: userList && Object.keys(userList).length > 0 ? userList.tripId : '',
    transactionId: userList && Object.keys(userList).length > 0 ? userList.transactionId : '',
    bookingTime: userList && Object.keys(userList).length > 0 ? fDateTime(userList.createdAt) : '',
    zoneName: userList && Object.keys(userList).length > 0 ? userList.zoneName : '',
    driver: userList && Object.keys(userList).length > 0 ? userList.driverName : '',
    vehicle: userList && Object.keys(userList).length > 0 ? userList.vehicleNumber : '',
    pax: userList && Object.keys(userList).length > 0 ? userList.demand : '',
    fare: userList && Object.keys(userList).length > 0 ? userList.paymentAmount : '',
    refundAmount: userList && Object.keys(userList).length > 0 ? userList.refundAmount : '',
    manualRefundAmount: userList && Object.keys(userList).length > 0 ? userList.manualRefundAmount : '',
  });
  const [getBookings, setGetBookings] = useState([])
  const [disButton, setdisButton] = useState(true);
  const [loadings, setLoading] = useState(false);

  const getAPICalled = async () => {
    try {
      const result = await APIGetAllBookings(setLoading);
      if (result.data.length !== 0) {
        const { data } = result.data;
        const reducer = data.length > 0 ? data.map((x) => {
          return {
            _id: x._id,
            userName: x.userName,
          }
        }) : []
        setGetBookings(reducer);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      // console.log('GO HERE IF ERROR', err);
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg('Error!', err);
    }
  };

  const removeAllState = () => {
    setUserValue({
      userName: '',
      status: '',
      email: '',
      tripId: '',
      transactionId: '',
      bookingTime: '',
      zoneName: '',
      driver: '',
      vehicle: '',
      pax: '',
      fare: '',
      refundAmount: '',
      manualRefundAmount: '',
    });
    handleClose();
  };
  // first time load will called the get all bookings API
  useEffect(() => {
    getAPICalled();
  }, []);


  // to display the value in edit page
  useEffect(() => {
    if (editState && userList && Object.keys(userList).length > 0) {
      if (typeof (userList.refundAmount) === 'number' && typeof (userList.manualRefundAmount) !== 'number') {
        setUserValue({
          userName: userList.userName,
          status: userList.status,
          email: userList.email,
          tripId: userList.tripId,
          transactionId: typeof (userList.transactionId) === 'string' ? userList.transactionId : 'None',
          bookingTime: fDateTime(userList.createdAt),
          zoneName: userList.zoneName,
          driver: userList.driverName,
          vehicle: userList.vehicleNumber,
          pax: userList.demand,
          fare: userList.paymentAmount,
          refundAmount: userList.refundAmount,
          manualRefundAmount: 'None',
        })
      }
      else if (typeof (userList.refundAmount) !== 'number' && typeof (userList.manualRefundAmount) === 'number') {
        setUserValue({
          userName: userList.userName,
          status: userList.status,
          email: userList.email,
          tripId: userList.tripId,
          transactionId: typeof (userList.transactionId) === 'string' ? userList.transactionId : 'None',
          bookingTime: fDateTime(userList.createdAt),
          zoneName: userList.zoneName,
          driver: userList.driverName,
          vehicle: userList.vehicleNumber,
          pax: userList.demand,
          fare: userList.paymentAmount,
          refundAmount: 'None',
          manualRefundAmount: userList.manualRefundAmount,
        })
      }
      else if (typeof (userList.refundAmount) === 'number' && typeof (userList.manualRefundAmount) === 'number') {
        setUserValue({
          userName: userList.userName,
          status: userList.status,
          email: userList.email,
          tripId: userList.tripId,
          transactionId: typeof (userList.transactionId) === 'string' ? userList.transactionId : 'None',
          bookingTime: fDateTime(userList.createdAt),
          zoneName: userList.zoneName,
          driver: userList.driverName,
          vehicle: userList.vehicleNumber,
          pax: userList.demand,
          fare: userList.paymentAmount,
          refundAmount: userList.refundAmount,
          manualRefundAmount: userList.manualRefundAmount,
        })
      }
      else {
        setUserValue({
          userName: userList.userName,
          status: userList.status,
          email: userList.email,
          tripId: userList.tripId,
          transactionId: typeof (userList.transactionId) === 'string' ? userList.transactionId : 'None',
          bookingTime: fDateTime(userList.createdAt),
          zoneName: userList.zoneName,
          driver: userList.driverName,
          vehicle: userList.vehicleNumber,
          pax: userList.demand,
          fare: userList.paymentAmount,
          refundAmount: 'None',
          manualRefundAmount: 'None',
        })
      }
    } else {
      setUserValue({
        userName: '',
        status: '',
        email: '',
        tripId: '',
        transactionId: '',
        bookingTime: '',
        zoneName: '',
        driver: '',
        vehicle: '',
        pax: '',
        fare: '',
        refundAmount: '',
        manualRefundAmount: '',
      })
    }
  }, [userList]);

  return (
    <Modal
      open={modalClick}
      onClose={removeAllState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loadings && <CircularProgress color="success" sx={loadingStyle} />}
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {label}
          <br />
          <br />
          <div style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: '1fr 1fr',
            gap: '0px 24px',
            gridTemplateAreas: '. .',
            justifyContent: 'center',
            width: '100%',
          }}
          >
            <div>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Name" value={userValue.userName} />
                <TextField disabled={true} sx={{ width: '100%' }} label="Booking Status" value={capitalCase(userValue.status.replace(/_/g, " "))} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Email" value={userValue.email} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Transaction ID" value={userValue.transactionId} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Auto Refund Amount" value={userValue.refundAmount} />
                <TextField disabled={true} sx={{ width: '100%' }} label="Manual Refund Amount" value={userValue.manualRefundAmount} />
              </FormControl>
            </div>
            <div>
              <FormControl sx={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridTemplateColumns: '1fr 1fr',
                gap: '0px 8px',
                gridTemplateAreas: '. .',
                justifyContent: 'center',
                marginBottom: '16px',
              }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Zone Name" value={userValue.zoneName} />
                <TextField disabled={true} sx={{ width: '100%' }} label="Booking Time" value={userValue.bookingTime} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', width: '100%' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Trip ID" value={userValue.tripId} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Driver" value={userValue.driver} />
                <TextField disabled={true} sx={{ width: '100%' }} label="Vehicle" value={userValue.vehicle} />
              </FormControl>
              <FormControl sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <TextField disabled={true} sx={{ width: '100%' }} label="Pax" value={userValue.pax} />
                <TextField disabled={true} sx={{ width: '100%' }} label="Fare" value={userValue.fare} />
              </FormControl>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );

}
