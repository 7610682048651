import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

// components
import {
    Box,
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
  } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import ModalVerification from '../components/dashboard/ModalVerification';
import TagsInput from '../components/TagsInput';
import MenuPopover from '../components/MenuPopover';
import SnackBar from '../components/Snackbar';
import { getImageFirebase } from '../utils/getImageFirebase';
import { APIGetUsers} from '../utils/apiCalled';
import User from './User';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_verify) => _verify.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);

  }

  const TABLE_HEAD = [
    { id: 'fullName', label: 'Full Name', alignRight: false },
    { id: 'nationality', label: 'Nationality', alignRight: false },
    { id: 'rejectionReason', label: 'Deep face remarks', alignRight: false },
  ];
  
  const loadingStyle = {
    position: 'absolute',
    zIndex: 10,
    top: '35%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
  };

  const testUndefined = null;


export default function VerificationModule() {

    const [page, setPage] = useState(0);
    const [modalEdit, setModalEdit] = useState(false);
    const [editState, setEditState] = useState(false);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
    const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
    // this to store the data when click one of the row
    const [detailsPage, setDetailsPage] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [variant, setVariant] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
    const [getUser, setGetUser] = useState([])
    const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // apicalled for getUser (verification)
    const getAPICalled = async () => {
        try {
            const statusParam = 'MANUAL'; 
            const result = await APIGetUsers(setLoading, statusParam);
            if (result.status === 200) {
                const { data } = result;
                setGetUser(data);
                setLoading(false);
            } else {
                setLoading(false);
                setOpen(true);
                setVariant('error');
                setErrMsg('Error!, Please try again');
            }
        } catch (err) {
            setLoading(false);
            setOpen(true);
            setVariant('error');
            setErrMsg('Error!', err);
        }
    };

    useEffect(() => {
        getAPICalled();
    }, [])

    // this after click reject or verify will called back the getUser API
    useEffect(() => {
        if (modalTriggerAPICalled) {
            getAPICalled();
            setModalTriggerAPICalled(false)
        }
    }, [modalTriggerAPICalled])

    // this useEffect for call the searchFullName API called. Interval for 0.5 sec
    // useEffect(() => {
    //     if (searchTimeout) {
    //         clearTimeout(searchTimeout); // this to clear time out
    //     }
    //     setSearchTimeout( // to buffer the search by 0.5 second
    //         setTimeout(() => {
    //             getAPICalled(); // call the api
    //         }, 500),
    //     );
    //     return () => clearTimeout(searchTimeout); // this also to clear time out
    // }, [filterName]);

    // const getImageDriver = (location, setState) => {
    //     // console.log('go heree heelloowwww driverImage------------>>>>>>>>');
    //     getImageFirebase(location).then((imgs) => {
    //       setState(imgs);
    //     }).catch((error) => {
    //       console.log(error);
    //     })
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    
    // snackbarClose
    const handleSnackBarClose = () => {
        setOpen(false);
    };
    
    
    const handleEditOpen = async (value) => {
        // console.log('value', value);
        setModalEdit(true);
        setDetailsPage(value);
        setEditState(true);
    };
    
    const handleEditCLose = () => {
        setModalEdit(false);
        setEditState(false);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getUser.length) : 0;

    const filteredUsers = applySortFilter(getUser, getComparator(order, orderBy), filterName);
    

  return (
    <Page title="Verification Module">
        <Container maxWidth="xl">
            <SnackBar
                open={open}
                alert={variant}
                handleClose={handleSnackBarClose}
                errorMsg={errMsg}
                successMsg={succMsg}
            />
            <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4">Verification Module</Typography>
          </Box>
          {loading && <CircularProgress color='success' sx={loadingStyle} /> }
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              popupfilter={setOpenFilterPopUp}
              labelName="Search Full Name..."
              displayFilter={false}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getUser.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, fullName, nationality, rejectionReason } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left" component="th" scope="row" onClick={() => handleEditOpen(row)}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                  {fullName}
                              </Stack>
                            </TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{nationality}</TableCell>
                            <TableCell align="left" onClick={() => handleEditOpen(row)}>{rejectionReason}</TableCell>  
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getUser.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <ModalVerification 
            handleClose={handleEditCLose}
            modalClick={modalEdit}
            label='Manual Verification'
            userList={detailsPage}
            editState={editState}
            setModalTriggerAPICalled={setModalTriggerAPICalled}
            setSuccMsg={setSuccMsg}
            setErrMsg={setErrMsg}
            setVariant={setVariant}
            setOpen={setOpen}
            modalEdit={modalEdit}
          />
        </Container>
    </Page>
  )
}
