import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import ModalRefund from '../../../components/dashboard/ModalRefund'
import ModalConfirmRefund from '../../../components/dashboard/ModalConfirmRefund';

// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function BookingMoreMenu({ handleSeeMore, bookingList, handleRefundOpen,
  handleRefundClose, modalRef, handleConfirm, handleConfirmOpen, handleConfirmClose,
  modalConfirm, tripId, amount, reason, loading, disableConfirmButton, disableCancelButton, maxAmount }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {maxAmount !== 0 && (
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleRefundOpen()}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Refund Booking" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>)}

        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleSeeMore(bookingList)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="See More" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <ModalRefund
          modalRef={modalRef}
          handleRefundClose={handleRefundClose}
          tripId={tripId}
          handleConfirmOpen={handleConfirmOpen}
          maxAmount={maxAmount}
        />
        <ModalConfirmRefund
          modalConfirm={modalConfirm}
          handleConfirm={handleConfirm} //API call
          handleConfirmClose={handleConfirmClose}
          amount={amount}
          tripId={tripId}
          reason={reason}
          loading={loading}
          disableConfirmButton={disableConfirmButton}
          disableCancelButton={disableCancelButton}
        />
      </Menu>
    </>
  );
}
