import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  borderRadius: '16px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function DeleteModal({
  handleDeleteClose, modalDel, handleDelete, label, data, isRejected, setRejectInput, rejectInput,
}) {
  const [disabledButton, setDisabledButton] = useState(true);
  useEffect(() => {
    if (rejectInput) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [rejectInput])
  
  // console.log('isRejected', isRejected);
  return (
    <Modal
      open={modalDel}
      onClose={handleDeleteClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {label}
          {
            isRejected && (
            <FormControl sx={{ marginBottom: '16px', marginTop: '16px', width: '100%' }}>
              <TextField id="standard-basic" type="text" label="Reject reason" onChange={(e) => setRejectInput(e.target.value)} />
            </FormControl>
            )
          }
          <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
            
            <Button variant="outlined" sx={{ width: 90 }} onClick={() => handleDeleteClose()}>No</Button>
            {
              isRejected ? (
                <Button variant="contained" sx={{ width: 90 }} disabled={disabledButton} onClick={() => handleDelete(data)}>Yes</Button>
              ) : (
                <Button variant="contained" sx={{ width: 90 }} onClick={() => handleDelete(data)}>Yes</Button>
              )
            }

          </Stack>
        </Typography>
      </Box>
    </Modal>
  );
}